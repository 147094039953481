<template>
  <v-app>
    <div v-if="localPropuesta != 'True'" class="menu">
      <div class="menu-container">
        <div class="logo">
          <img src="~@/assets/images/lucky-jacko.png" alt="Logo" class="logo-img" />
        </div>
        <div class="menu-title">Comparator pets</div>
        <div class="menu-bar">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="green" dark v-bind="attrs" v-on="on" class="menu-button">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openLogin">
                <v-list-item-icon>
                  <v-icon color="green">mdi-login</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Iniciar sesión</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green">mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Inicio</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green">mdi-magnify</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Buscar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <div :class="imagenTop"></div>
    <v-main>
      <router-view />
    </v-main>
    <Footer v-if="localPropuesta != 'True' && !this.pathname.includes('relationship')" />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
  },
  data: () => ({
    screen: window.innerWidth,
    imagenTop: '',
    pathname: window.location.pathname,
    localPropuesta: null,
  }),
  mounted() {
    this.localPropuesta = localStorage.getItem('Propuesta');
    this.updateImagenTop();
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods: {
    updateScreenSize() {
      this.screen = window.innerWidth;
      this.updateImagenTop();
    },
    updateImagenTop() {
      if (this.localPropuesta !== 'True') {
        if (this.screen > 800) {
          switch (this.pathname) {
            case '/comparation/pets':
              this.imagenTop = 'vistaSalchichaDesk';
              break;

            default:
              this.imagenTop = 'vistaSalchichaDesk';
              break;
          }
        } else {
          switch (this.pathname) {
            case '/comparation/pets':
              this.imagenTop = 'vistaSalchichaMovile';
              break;

            default:
              this.imagenTop = 'vistaSalchichaMovile';
              break;
          }
        }
      } else {
        if (this.screen > 800) {
          switch (this.pathname) {
            case '/comparation/pets':
              this.imagenTop = 'vistaSalchichaDesk2';
              break;

            default:
              this.imagenTop = 'vistaSalchichaDesk2';
              break;
          }
        } else {
          switch (this.pathname) {
            case '/comparation/pets':
              this.imagenTop = 'vistaSalchichaMovile2';
              break;

            default:
              this.imagenTop = 'vistaSalchichaMovile2';
              break;
          }
        }
      }

    },
    openLogin() {
      console.log('Abrir inicio de sesión');
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
};
</script>

<style>
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.menu {
  background-color: white;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.menu-title {
  color: rgba(16, 148, 15, 1);
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
}

.menu-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 40px;
}

.menu-bar {
  display: flex;
  align-items: center;
}

.menu-button {
  border-radius: 4px;
  min-width: 48px;
  height: 48px;
}

.v-list-item-title {
  font-weight: 400;
  font-size: 18px;
  color: #333;
}

.v-list-item-icon {
  margin-right: 8px;
}

.vistaSalchichaDesk {
  background-image: url("~@/assets/images/pruebaPerrito.jpg");
  background-position: center;
  width: 100%;
  height: 500px;
}

.vistaSalchichaMovile {
  background-image: url("~@/assets/images/pruebaPerrito.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 200px;
}

.vistaSalchichaDesk2 {
  background-image: url("~@/assets/images/rosas.jpg");
  background-position: center;
  width: 100%;
  height: 500px;
}

.vistaSalchichaMovile2 {
  background-image: url("~@/assets/images/rosas.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 200px;
}
</style>
