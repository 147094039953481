import Vue from "vue";
import VueRouter from "vue-router";
import Comparation from "../views/Comparation.vue";
import Propuesta from "../views/Propuesta.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/comparation/pets",
  },
  {
    path: "/comparation/pets",
    name: "Dachshund-lovers",
    component: Comparation,
    meta: {
      title: 'Dachshund-lovers', 
      metaTags: [
        {
          name: 'description',
          content: 'Dachshund-lovers'
        },
        {
          property: 'og:description',
          content: 'Dachshund-lovers'
        }
      ]
    }  
  },
  {
    path: "/relationship/love",
    name: "Querida Genésis",
    component: Propuesta,
    meta: {
      title: 'Querida Genésis', 
      metaTags: [
        {
          name: 'description',
          content: 'Querida Genésis'
        },
        {
          property: 'og:description',
          content: 'Querida Genésis'
        }
      ]
    }  
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
