<template>
    <div id="app">

        <!-- Vista principal -->
        <div>

            <!-- Cookies -->
            <v-dialog v-model="showCookieDialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text-h6">Aviso de Cookies</v-card-title>
                    <v-card-text>
                        <p>Usamos cookies para mejorar tu experiencia en este sitio. Acepta para continuar.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="buttonCokies" style="background-color: rgba(35, 214, 33, 1); color: white;"
                            @click="acceptCookies">Aceptar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <div v-if="!showComparison">

                <br>
                <br>

                <!-- Titulo -->
                <div>

                    <div class="highlight-div">
                        <p class="highlight-text"> ¿Qué imágen se parece más a un perro salchicha? 🐾 ¡Sube las imágenes
                            y compara! 🐕✨</p>
                    </div>

                </div>

                <br>

                <!-- Comparación -->
                <div v-if="loading" class="loading-screen">
                    <div v-if="photos.ignacio && photos.genesis" class="comparison-container">
                        <div class="photo top-photo">
                            <img :src="photos.ignacio" alt="Ignacio" />
                            <p class="comparationFont">Imágen de: Ignacio</p>
                        </div>

                        <br>

                        <div class="loading-container">
                            <v-progress-circular :size="100" :value="progress" color="green"
                                style="font-size: 20px;">VS</v-progress-circular>
                            <h2>Comparando: {{ progress.toFixed(2) }}%</h2>
                        </div>

                        <br>

                        <div class="photo bottom-photo">
                            <img :src="photos.genesis" alt="Génesis" />
                            <p class="comparationFont">Imágen de: Génesis</p>
                        </div>
                    </div>
                </div>

                <br>
                <br>

                <!-- Primer Participante -->
                <div v-if="showParticipantes" class="upload-container">

                    <template>
                        <v-card style="background-color: rgba(16, 148, 15, 0.4);" class="dialogDiv mx-auto"
                            max-width="344" hover>
                            <v-card-item>
                                <v-card-title>
                                    <v-text-field class="LabelName" v-model="firstName" filled solo
                                        label="Participante N° 1"></v-text-field>
                                </v-card-title>

                            </v-card-item>

                            <v-card-text v-if="firstName">

                                <v-file-input solo :rules="rules" accept="image/png, image/jpeg, image/bmp"
                                    :label="`Imágen ${firstName ? 'de ' + firstName : ''}`"
                                    placeholder="Seleccione imágen" prepend-icon="mdi-camera"
                                    @change="handleFileUpload(identifiedFirst)">
                                </v-file-input>

                                <p class="text-center" style="font-size: 10px; margin-top: -5%;">
                                    *Debe cargar una imagen referencial
                                </p>

                            </v-card-text>

                        </v-card>
                    </template>

                </div>

                <br>

                <br>

                <!-- Segundo Participante -->
                <div v-if="showParticipantes" class="upload-container">

                    <template>
                        <v-card style="background-color: rgba(16, 148, 15, 0.4);" class="dialogDiv mx-auto"
                            max-width="344" hover>
                            <v-card-item>
                                <v-card-title>
                                    <v-text-field class="LabelName" v-model="secondName" filled solo
                                        label="Participante N° 2"></v-text-field>
                                </v-card-title>

                            </v-card-item>

                            <v-card-text v-if="secondName">

                                <v-file-input solo :rules="rules" accept="image/png, image/jpeg, image/bmp"
                                    :label="`Imágen ${secondName ? 'de ' + secondName : ''}`"
                                    placeholder="Seleccione imágen" prepend-icon="mdi-camera"
                                    @change="handleFileUpload(identifiedSecond)">
                                </v-file-input>

                                <p class="text-center" style="font-size: 10px; margin-top: -5%;">
                                    *Debe cargar una imagen referencial
                                </p>

                            </v-card-text>

                        </v-card>
                    </template>

                </div>

                <!-- Pregunta -->
                <div>

                    <br v-if="showParticipantes">
                    <br v-if="showParticipantes">

                    <v-button v-if="!loading && showParticipantes" class="comparar-button" @click="comparePhotos">
                        ¿Quién es el(la) ganador(a)?
                    </v-button>
                    <v-button v-if="loading && showParticipantes" disabled class="comparar-button-disabled">
                        ¿Quién es el(la) ganador(a)?
                    </v-button>

                    <br v-if="showParticipantes">
                    <br v-if="showParticipantes">

                </div>

            </div>

            <!-- Felicitaciones -->
            <v-dialog v-model="dialog" max-width="500px">
                <v-card class="d-flex flex-column align-center">
                    <v-card-title class="text-center justify-center">
                        🎉 ¡Felicitaciones! 🎉
                    </v-card-title>
                    <v-card-text class="text-center">
                        <p>¡Génesis gana! 🎉</p>
                        <p>Con un 58% de preferencia.</p>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            style="background-color: rgba(16, 148, 15, 0.8); color: white; width: 200px; text-transform:initial;"
                            @click="closeDialog">
                            Generar premio
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </div>

    </div>
</template>

<script>

import swal from 'sweetalert';
import confetti from "canvas-confetti";
import Cookies from "js-cookie";

export default {
    data() {
        return {
            cookiesAccepted: false,
            showCookieDialog: false,
            loading: false,
            photosLoaded: false,
            showComparison: false,
            progress: 0,
            photos: {
                ignacio: null,
                genesis: null,
            },
            noButtonPosition: {
                top: "50%",
                left: "50%",
            },
            firstName: null,
            secondName: null,
            dialog: false,
            localPropuesta: null,
            showParticipantes: true,
        };
    },
    watch: {
        firstName(newName) {
            this.identifiedFirst = this.identifyName(newName);
        },
        secondName(newName) {
            this.identifiedSecond = this.identifyName(newName);
        },
    },
    created() {
        this.cookiesAccepted = Cookies.get("cookiesAccepted") === "true";
        this.showCookieDialog = !this.cookiesAccepted;
    },
    mounted() {
        this.localPropuesta = localStorage.getItem('Propuesta');
    },
    methods: {
        acceptCookies() {
            this.cookiesAccepted = true;
            this.showCookieDialog = false;
        },
        identifyName(name) {
            if (!name) return null;

            const variations = {
                genesis: ["genesis", "génesis", "gen", "geny", "gén", "gene"],
                ignacio: ["ignacio", "nacho", "ignacito", "igni"],
            };

            const lowerName = name.toLowerCase();

            for (const [key, values] of Object.entries(variations)) {
                if (values.includes(lowerName)) {
                    return key;
                }
            }

            return null;
        },
        startLoading() {
            this.showParticipantes = false;
            const duration = 300000;
            const interval = 100;
            const updates = duration / interval;
            const increment = 100 / updates;
            let progress = 0;

            const timer = setInterval(() => {
                this.progress += increment;

                if (this.progress >= 100) {
                    clearInterval(timer);
                    this.progress = 100;
                    this.acceptProposal();
                    this.launchConfetti();
                }

                progress++;
            }, interval);
        },
        launchConfetti() {
            const duration = 3 * 1000;
            const animationEnd = Date.now() + duration;

            const confettiInterval = setInterval(() => {
                if (Date.now() > animationEnd) {
                    clearInterval(confettiInterval);
                    return;
                }

                confetti({
                    particleCount: 50,
                    spread: 70,
                    origin: { x: Math.random(), y: Math.random() - 0.2 },
                });
            }, 250);
        },
        closeDialog() {
            this.dialog = false;
            this.loading = false;
            localStorage.setItem('Propuesta', 'True');
            window.location.href = "/relationship/love";
        },
        handleFileUpload(person) {
            const fileInput = event.target.files[0];
            if (fileInput) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.photos[person] = e.target.result;
                };
                reader.readAsDataURL(fileInput);
            }
        },
        comparePhotos() {
            if (this.photos.ignacio && this.photos.genesis && this.firstName && this.secondName) {
                this.photosLoaded = true;
                this.loading = true;
                this.startLoading();
            } else if (!this.firstName || !this.secondName) {
                swal({
                    title: "Lo sentimos! 😢",
                    text: "Ingrese los nombres de los participantes.",
                    icon: "info",
                    button: "Ok",
                }).then(() => {
                    this.showComparison = false;
                });
            } else if (!this.photos.ignacio || !this.photos.genesis) {
                swal({
                    title: "Lo sentimos! 😢",
                    text: "Cargue las imágenes correspondientes.",
                    icon: "info",
                    button: "Ok",
                }).then(() => {
                    this.showComparison = false;
                });
            }
        },
        moveNoButton() {
            const container = document.querySelector(".button-container");
            const containerRect = container.getBoundingClientRect();
            const buttonWidth = 80;
            const buttonHeight = 40;

            const newTop = Math.random() * (containerRect.height - buttonHeight);
            const newLeft = Math.random() * (containerRect.width - buttonWidth);

            this.noButtonPosition = {
                top: `${newTop}px`,
                left: `${newLeft}px`,
            };
        },
        acceptProposal() {
            const percentage = (Math.random() * 10 + 50).toFixed(2);

            swal({
                title: `¡Génesis gana! 🎉`,
                text: `Con un ${percentage}% de preferencia.`,
                icon: "success",
                button: "Consultar premio",
            }).then(() => {
                localStorage.setItem('Propuesta', 'True');
                window.location.href = "/relationship/love";
            });
        }

    },
};
</script>

<style>
#app {
    text-align: center;
    font-family: Arial, sans-serif;
    /* background-color: rgba(45, 229, 42, 0.6); */
}

.comparationFont {
    font-size: 14px;
    margin-top: 5%;
    color: rgba(16, 148, 15, 0.8);
}

.propuestaText {
    color: #ff69b4;
}

.couple-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.couple-image {
    margin: 0 20px;
}

.couple-image img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
    border: 2px solid #f9c0c0;
}

.couple-text h2 {
    color: #ff69b4;
    font-size: 1.5em;
    font-weight: bold;
}

.couple-text p {
    color: #ff9c9c;
    font-size: 1em;
    font-style: italic;
}

.loading-container {
    font-size: 10px;
    color: rgba(16, 148, 15, 0.8);
}

.dialogDiv {
    background-color: rgba(16, 148, 15, 0.8);
}

.cookie-banner {
    position: fixed;
    bottom: 1;
    background: rgba(16, 148, 15, 0.8);
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
}

.cookie-banner button {
    background: #28a745;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 5px;
}

.title {
    margin: auto;
    text-align: center;
    color: rgba(16, 148, 15, 1);
    font-size: 24px;
    font-weight: 600;
    transition: transform 0.3s;
}

.title:hover {
    transform: scale(1.05);
}

.highlight-div {
    background: linear-gradient(45deg, #12bb15, #14c1bc);
    padding: 20px;
    margin-top: 20px;
    border-radius: 15px;
    animation: pulse 2s infinite;
    max-width: 350px;
    margin: auto;
}

.highlight-text {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    animation: bounce 1.5s ease infinite;
}

.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comparison-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-photo {
    margin-bottom: 10px;
}

.bottom-photo {
    margin-top: 10px;
}

.photo img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.upload-container {
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.rectangle-shape {
    position: relative;
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.rectangle-shape label {
    position: absolute;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    z-index: 1;
}

.upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comparison-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.photo img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.button-container {
    position: relative;
    height: 300px;
    margin: 0 auto;
    width: 80%;
}

button {
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.yes-button {
    background: linear-gradient(45deg, #4caf50, #81c784);
    color: white;
    border: 2px solid #388e3c;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.yes-button:hover {
    background: linear-gradient(45deg, #81c784, #4caf50);
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.no-button {
    background: linear-gradient(45deg, #e53935, #ef5350);
    color: white;
    border: 2px solid #c62828;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
}

.no-button:hover {
    background: linear-gradient(45deg, #ef5350, #e53935);
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.comparar-button {
    background: rgba(34, 137, 33, 1);
    color: white;
    border: 2px solid #28c657;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.comparar-button:hover {
    background: rgba(35, 214, 33, 1);
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.buttonCokies {
    color: white;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.buttonCokies:hover {
    background: rgba(35, 214, 33, 1);
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.comparar-button-disabled {
    background: rgb(148, 150, 153);
    color: white;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.comparar-button-disabled:hover {
    background: rgb(148, 150, 153);
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}
</style>
