<template>
  <div>

    <br v-if="screen > 800">
    <br>

    <div class="text-center footer">

      <br v-if="screen > 800">
      <br>

      <label class="footerLetter">Política de privacidad</label>
      <label class="footerLetter">Términos y condiciones</label>
      <label class="footerLetter">Contacto</label>
      <label class="footerLetter">Dirección</label>

      <div class="image">
        <v-img contain max-height="130px" :src="logoImage" max-width="245" style="margin: auto;" />
      </div>

    </div>
  </div>
</template>

<script>
import logoImage from "@/assets/images/salchichasAmantes.jpg";

export default {
  name: "Footer",
  data: () => ({
    logoImage,
    path: window.location.pathname,
    screen: window.screen.width,
  }),
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer {
  background-color: rgba(100, 204, 18, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image {
  margin-bottom: 10px;
}

label {
  font-size: 14px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  margin: 5px 0;
}

.footerLetter {
  color: green;
  font-size: 14px;
}
</style>
