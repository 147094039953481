<template>
    <div id="app" class="proposal-container">

        <div class="message-container" v-if="primerStep">
            <i class="message-text">
                "Ha pasado bastante tiempo desde el primer día que nos conocimos, y estoy muy agradecido por la
                oportunidad de habernos encontrado y congeniado en esta etapa de nuestras vidas. 💖"
            </i>
        </div>

        <div class="gif-container" v-if="primerStep">
            <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExOGxpeTB0dTU0bGFnZnplaWJ3eTZxdzhiaWdseWg5NHhiZng1MzJncyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/XN8pBJ6HrLTOM/giphy.gif"
                alt="Corazones flotantes" class="proposal-gif" />
        </div>

        <div class="message-container" v-if="primerStep">
            <i class="message-text">
                "Hemos compartido hermosos viajes a lo largo de Chile, y pronto nos espera un vaije más largo y
                emocionante que los que hemos vivido hasta ahora. Pero aclaro que mis ganas son cada vez más grandes
                cuando te incluyen a ti. 💖"
            </i>
        </div>

        <div class="gif-container" v-if="primerStep">
            <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMGhqMjF6YmF5MXEyMXE1Znk2Z2k1ZzUya3JjZ2xld2oycDBsenhtMiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oz8xLYF0gagozmsgM/giphy.gif"
                alt="Gusto por la cerveza" class="proposal-gif" />
        </div>

        <div class="message-container" v-if="primerStep">
            <i class="message-text">
                "Nuestro gusto compartido por la cerveza hace que disfrute aún más esos momentos. Manifiesto que de aquí
                en
                adelante podamos saborear y brindar con muchas birras más. 🍺"
            </i>
        </div>

        <div class="gif-container" v-if="primerStep">
            <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZm04YmE5NDZzc2Z4amlpNGM0azdodzJyYTRkaWYzb2dicWEwN2kxaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26FPDJ6ZRqCHf3F1C/giphy.gif"
                alt="Gusto por la cerveza" class="proposal-gif" />
        </div>

        <div class="message-container" v-if="primerStep">
            <i class="message-text">
                "También, cuidando siempre nuestro estado físico, sigamos compartiendo el gusto de probar locales y
                nuevos platos de comida. Es algo que nos identifica también como pareja. 🫶💫"
            </i>
        </div>

        <div class="gif-container" v-if="primerStep">
            <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExaTUzN2piNXh3YXNqcDdsYXE3a3FwNGZnODgxcmJ0dmxseWttaHR4byZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/G1SuUoS0Fgskpx1jHZ/giphy.gif"
                alt="Valores compartidos" class="proposal-gif" />
        </div>

        <div class="message-container" v-if="primerStep">
            <i class="message-text">
                "Por otro lado me encanta que mi familia te sienta como una más en casa, no creo que le tomes el peso
                pero es lindo sentir aquello, porque significa que tus valores, personales y hacia los animalitos, son
                compartidos en nuestro hogar. Por lo mismo me gustaría llegar a conocer el tuyo tambien. 💕💫"
            </i>
        </div>

        <div class="gif-container" v-if="primerStep">
            <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExdHJ5aThjZTA1ZmQ2cWozNHQxbGx1ZzB6cDkyNW5qZWlhMmoxdjZuZSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/WrJNAaVSgT4NUbF5V8/giphy.gif"
                alt="Valores compartidos" class="proposal-gif" />
        </div>

        <div class="message-container" v-if="primerStep">
            <i class="message-text">
                "Entiendo que una relación no siempre es color de rosas. Habrá momentos difíciles, y situaciones que
                pondrán a prueba nuestra relación. Pero de algo sí estoy seguro: con comunicación, amor y apoyo mutuo,
                podremos superar cualquier obstáculo. Porque siento que sí vale la pena intentarlo. ❤️"
            </i>
        </div>

        <div class="images-container" v-if="primerStep">
            <div class="image-wrapper" v-for="(image, index) in images" :key="index">
                <img :src="image.src" :alt="image.alt" class="proposal-image" />
            </div>
        </div>

        <div class="button-container" v-if="primerStep">
            <h1 class="proposal-text">¿Querrías ser mi polola? 💖</h1>

            <v-button class="yes-button" @click="acceptProposal">Sí 💕</v-button>

            <v-button class="no-button" :style="{ top: noButtonPosition.top, left: noButtonPosition.left }"
                @mouseover="moveNoButton">
                No 😅
            </v-button>
        </div>

        <!-- Nueva vista cuando primerStep es falso -->
        <div v-if="!primerStep" class="kiss-view">
            <div class="kiss-video-container">
                <iframe width="350" height="350"
                    src="https://www.youtube.com/embed/fS1YqgegU_M?autoplay=1&fs=1&loop=1&playlist=fS1YqgegU_M"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowfullscreen>
                </iframe>
            </div>

            <br>
            <br>

            <div class="kiss-text-container">
                <p class="kiss-text">¡Que nunca se acaben nuestras aventuras! 💕</p>
                <p class="kiss-text">Gracias por confiar en que podemos lograr un futuro acompañado el uno del otro.</p>
            </div>

            <div class="game-container">
                <h2>💖Jueguemos! el que gana invita una cita romantica💖</h2>
                <br>
                <br>
                <div class="grid">
                    <div v-for="(cell, index) in board" :key="index" class="cell" @click="makeMove(index)">
                        {{ cell }}
                    </div>
                </div>
                <p v-if="winner" class="winner-text">{{ winnerMessage }}</p>
                <button class="reset-button" @click="resetGame">Reiniciar Juego</button>
            </div>

        </div>


    </div>
</template>


<script>
import confetti from "canvas-confetti";

export default {
    data() {
        return {
            images: [
                { src: require("@/assets/images/primeraImagen.jpg"), alt: "Pareja 1" },
                { src: require("@/assets/images/segundaImagen.jpg"), alt: "Pareja 2" },
                { src: require("@/assets/images/terceraImagen.jpg"), alt: "Pareja 3" },
            ],
            noButtonPosition: {
                top: "50%",
                left: "50%",
            },
            primerStep: true,
            board: Array(9).fill(""),
            currentPlayer: "X",
            winner: null,
            winnerMessage: ""
        };
    },
    methods: {
        launchConfetti() {
            const duration = 3 * 1000;
            const animationEnd = Date.now() + duration;

            const confettiInterval = setInterval(() => {
                if (Date.now() > animationEnd) {
                    clearInterval(confettiInterval);
                    return;
                }

                confetti({
                    particleCount: 50,
                    spread: 70,
                    origin: { x: Math.random(), y: Math.random() - 0.2 },
                });
            }, 250);
        },

        moveNoButton() {
            const container = document.querySelector(".button-container");
            const containerRect = container.getBoundingClientRect();
            const buttonWidth = 80;
            const buttonHeight = 40;

            const newTop = Math.random() * (containerRect.height - buttonHeight);
            const newLeft = Math.random() * (containerRect.width - buttonWidth);

            this.noButtonPosition = {
                top: `${newTop}px`,
                left: `${newLeft}px`,
            };
        },
        acceptProposal() {
            this.launchConfetti();
            swal({
                title: "¡Sabía que dirías que sí! 💕",
                text: "¡Eres la mejor! 😊",
                icon: "success",
                button: "¡Qué felicidad! 💖",
            }).then(() => {
                this.primerStep = false;
                this.$nextTick(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                });
            });
        },
        makeMove(index) {
            if (!this.board[index] && !this.winner) {
                this.$set(this.board, index, this.currentPlayer);
                if (this.checkWinner()) {
                    this.winnerMessage = `¡Felicidades! Ganaste una cita romántica 💖`;
                    setTimeout(() => {
                        alert(this.winnerMessage);
                    }, 500);
                } else {
                    this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";
                }
            }
        },
        checkWinner() {
            const winPatterns = [
                [0, 1, 2], [3, 4, 5], [6, 7, 8],
                [0, 3, 6], [1, 4, 7], [2, 5, 8],
                [0, 4, 8], [2, 4, 6]
            ];
            for (let pattern of winPatterns) {
                const [a, b, c] = pattern;
                if (this.board[a] && this.board[a] === this.board[b] && this.board[a] === this.board[c]) {
                    this.winner = this.board[a];
                    return true;
                }
            }
            return false;
        },
        resetGame() {
            this.board = Array(9).fill("");
            this.currentPlayer = "X";
            this.winner = null;
            this.winnerMessage = "";
        }
    },
};
</script>

<style>
.game-container {
    text-align: center;
    margin-top: 20px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    gap: 10px;
    justify-content: center;
}

.cell {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background-color: #ffccf2;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
}

.winner-text {
    margin-top: 10px;
    font-size: 1.5rem;
    color: #ff69b4;
    font-weight: bold;
}

.kiss-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.kiss-image-container {
    margin-bottom: 20px;
}

.kiss-image {
    width: 400px;
    height: auto;
}

.kiss-text-container {
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;
}

.proposal-container {
    text-align: center;
    padding: 20px;
    position: relative;
    width: 100%;
    background-color: #ffe6e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.message-container {
    margin: 20px 0;
    padding: 20px;
    background: linear-gradient(135deg, #ffe4e1, #ffccf2);
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 600px;
}

.message-text {
    font-size: 18px;
    font-family: "Cursive", sans-serif;
    color: #ff69b4;
    text-align: center;
    margin: 0;
}

.proposal-text {
    font-size: 18px;
    color: #ff69b4;
    margin-bottom: 30px;
    font-family: "Cursive", sans-serif;
}

.images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.image-wrapper {
    margin: 10px;
}

.proposal-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid #ff69b4;
}

/* GIFs */
.gif-container {
    margin: 20px 0;
}

.proposal-gif {
    width: 200px;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 2s ease-in-out;
}

.yes-button,
.no-button {
    font-size: 1.2rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.yes-button {
    background-color: #ff69b4;
    color: white;
}

.no-button {
    background-color: #fff;
    color: #ff69b4;
    border: 2px solid #ff69b4;
    position: absolute;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>
